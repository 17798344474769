import Wrapper from 'components/Wrapper';
import { Heading, Flex } from 'rebass/styled-components';
import { COLOR } from 'styles/constants';
import PamNCard from './PamNCard';
import PamRCard from './PamRCard';
import { Fade } from 'react-awesome-reveal';

const About = () => {
  return (
    <Flex bg={COLOR.tan} width={1} justifyContent="center" py={[5, 5, 6]}>
      {/* <Fade triggerOnce> */}
      <Wrapper flexDirection="column">
        <Flex alignItems="flex-start" flexDirection={['column', 'row']}>
          <Flex width={1} mr={[0, 4, 4, 5]} flexDirection="column" alignItems={['flex-start', 'flex-start']}>
            <Heading as="h2" fontFamily="script" fontSize={['68px', '68px', '100px']} mb={[3, 4]}>
              About Us
            </Heading>
            <Heading
              as="h2"
              mb={[4, 5]}
              fontFamily="sans"
              fontSize={[1, 1, 2]}
              color={COLOR.brown}
              sx={{ textTransform: 'uppercase', letterSpacing: '3px' }}
            >
              Meet the Facilitators
            </Heading>
            <PamNCard />
          </Flex>
          <PamRCard />
        </Flex>
      </Wrapper>
      {/* </Fade> */}
    </Flex>
  );
};

export default About;
