import { Text, Heading, Box, Flex } from 'rebass/styled-components';
import { COLOR } from 'styles/constants';
import Wrapper from './Wrapper';

const Blockquote = () => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      bg={COLOR.tan}
      py={[5, 5, 6]}
      width={1}
      sx={{ position: 'relative' }}
    >
      <Wrapper justifyContent="center">
        <Text
          as="p"
          fontSize={[2, 3, 4]}
          textAlign={['center']}
          fontFamily="serif"
          maxWidth={[360, 500, 590]}
          sx={{ zIndex: 1, lineHeight: 1.4 }}
        >
          “I quietly ventured into nature and suddenly, there was magic all around me”
        </Text>
        <br />
        {/* <Box as="img" src="/img/pine-white.png" alt="" height={'100%'} sx={{ position: 'absolute', left: 0, top: 0 }} /> */}
      </Wrapper>
    </Flex>
  );
};

export default Blockquote;
