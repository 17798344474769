const COLOR = {
  //   cream: '#f2efea',
  //   primary: '#1a231a',
  //   white: 'white',

  // one
  // darkGreen: '#575844',
  // lightGreen: '#E3E2DB',
  white: '#ffffff',

  //two
  // tan: '#efefef',
  // tan: '#eae3e0',
  tan: '#EFEFEC',
  brown: '#444243',
  //   white: 'FFFFFF',
};

const MAX_WIDTH_DESKTOP = 1200;

export { COLOR, MAX_WIDTH_DESKTOP };
