import { COLOR } from './constants';

const theme = {
  fonts: {
    serif: 'PT Serif, serif',
    sans: '"PT Sans", sans-serif',
    script: '"Puzzled", cursive',
  },
  breakpoints: ['40em', '52em', '64em'],

  colors: {
    primary: COLOR.brown,
  },
  fontSizes: [12, 16, 19, 24, 32, 48, 64],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  buttons: {
    primary: {
      borderRadius: 0,
      border: '1px solid transparent',
      color: COLOR.white,
      backgroundColor: COLOR.brown,
      padding: ['8px 16px', '15px 20px'],
    },
    inverse: {
      borderRadius: 0,
      color: COLOR.brown,
      backgroundColor: COLOR.tan,
      padding: ['8px 16px', '15px 20px'],
      border: `1px solid ${COLOR.brown}`,
    },
    inverseWhite: {
      borderRadius: 0,
      color: COLOR.brown,
      backgroundColor: COLOR.white,
      padding: ['8px 16px', '15px 20px'],
      border: `1px solid ${COLOR.brown}`,
    },
  },
};
export default theme;
