import { Text, Heading, Flex, Box } from 'rebass/styled-components';
import { COLOR } from 'styles/constants';
import { Fade } from 'react-awesome-reveal';

const Card = () => {
  return (
    <Box width={1} mb={[4, 0]}>
      {/* <Fade triggerOnce> */}
      <Flex flexDirection="column">
        <Flex
          as="picture"
          width={[0.6, 0.6, 0.6, 0.44]}
          minWidth={200}
          height="auto"
          mb={[4, 4, 4, '-95px']}
          sx={{ zIndex: 1 }}
          alignSelf={['center', 'center', 'center', 'flex-end']}
        >
          <source type="image/webp" srcSet="/img/pamn-mobile.webp" media="(max-width: 1023px)" />
          <source type="image/webp" srcSet="/img/pamNovak.webp" media="(min-width: 1023px)" />
          <source type="image/png" media="(max-width: 1023px)" srcSet="/img/pamn-mobile.png 358w" />
          <source type="image/png" media="(min-width: 1024px)" srcSet="/img/pamNovak.png 884w" />
          <img
            src="/img/pamNovak.png"
            alt="Pam Robertson smiling in front of a Montana mountain range"
            loading="lazy"
          />
        </Flex>
        <Box
          p={[4, 4, '38px', '45px']}
          pb={5}
          bg={COLOR.white}
          sx={{ boxShadow: '10px 10px 28px 0 rgb(0 0 0 / 10%)' }}
          width={['100%', '100%', '100%', '90%']}
          mb={[4, 0]}
        >
          <Heading as="h3" mb={2} fontSize={[3, 4]} fontFamily="serif">
            Pam Novak
          </Heading>
          <Text as="p" fontSize={1} fontFamily="sans" maxWidth="500px" mb={4}>
            Clancy, Montana
          </Text>
          <Text as="p" fontSize={1} fontFamily="sans" lineHeight={1.4}>
            Pam Novak lives in Montana, holds degrees in Business and Psychology, is an IIN-certified Integrative
            Nutrition Health Coach and a recovering workplace multi-tasker/ladder-climber/over-thinker. After 25+ years
            of craving work-life balance, Pam has learned to trust her intuition and is leaning-in to a mission and
            purpose of empowering others to build resilience, recognize their strengths, define success, focus on
            selfcare, and create their own amazing story.
            <br />
            <br />
            She believes in the power of synchronicity, in self-actualization and that simply showing up for people is
            the most important element of humankind. She isn't an over-zealous health guru, doesn’t really like kale,
            and will probably never give up chocolate! She is guided by the principle of bio-individuality and is
            constantly redefining what a healthy lifestyle looks like — through both primary and secondary foods. <br />
            <br />
            As a co-founder of Northern Pine Wellness Group, she is answering a calling to build 'communitity' through
            positivity, connection, loving-kindness and the healing power of nature. Along with her sweet dog, Piper,
            her heart is silently drawn to the mountains, riverbanks, fresh air, firesides, and wherever sunlight
            dances.
          </Text>
        </Box>
      </Flex>
      {/* </Fade> */}
    </Box>
  );
};

export default Card;
