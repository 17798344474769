import { COLOR } from 'styles/constants';
import { Icon } from './Icons/Icon';
import { Flex, Box, Text, Image } from 'rebass/styled-components';
import Wrapper from 'components/Wrapper';

const Footer = () => {
  return (
    <Flex pt={[4, 5]} pb={[3, 4]} bg={COLOR.white} justifyContent="center">
      <Wrapper
        display="flex"
        justifyContent="space-between"
        flexDirection={['column', 'row']}
        alignItems={['center', 'flex-end']}
        gap="5%"
      >
        <Image width={['60px', '80px']} src="img/badge.png" alt="INHC Health Coach badge" my={[3, 0]} />
        <Flex
          justifyContent="space-between"
          width={1}
          flexDirection={['column', 'column']}
          alignItems={['center', 'flex-end']}
        >
          <Box display={['flex']} mb={[2, 3]}>
            <Box as="a" href="mailto:northernpinewellnessgroup.mtwa.com" aria-label="Email">
              <Icon type="email" padding="0 1rem 0 0" color={COLOR.brown} />
            </Box>
            <Box as="a" href="https://www.instagram.com/northernpinewellnessgroup_mtwa" aria-label="Instagram">
              <Icon type="insta" padding="0" color={COLOR.brown} />
            </Box>
          </Box>

          <Flex flexDirection={'row'} justifyContent="space-between">
            <Text as="p" fontFamily={'sans'} textAlign={'right'} alignSelf={'center'} fontSize={[0, 1]}>
              &#169; 2022 Northern Pine Wellness Group mt.wa
            </Text>
          </Flex>
        </Flex>
      </Wrapper>
    </Flex>
  );
};

export default Footer;
