import { Flex, Heading, Text, Box, Button, Image, Link } from 'rebass/styled-components';
import Wrapper from 'components/Wrapper';
import Signup from 'components/MailchimpForm';
import { COLOR } from 'styles/constants';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';

const Img = styled.img`
  @media (max-width: 40em) {
    height: auto;
    width: 380px;
  }

  max-height: 480px;
  width: auto;
`;

const Event = () => {
  return (
    <Flex width={1} bg={COLOR.white} justifyContent="center" py={[5, 5, 6]}>
      {/* <Fade triggerOnce> */}
      <Wrapper flexDirection={['column', 'row', 'row']}>
        <Flex width={'100%'} pr={[0, 4, 5, 5]} justifyContent="center" mb={[4, 0]} alignItems="center">
          <picture>
            <source type="image/webp" srcSet="/img/bridge-small.webp" media="(max-width: 1023px)" />
            <source type="image/webp" srcSet="/img/bridge.webp" media="(min-width: 1023px)" />
            <source type="image/png" srcSet="/img/bridge-small.png" media="(max-width: 1023px)" />
            <source type="image/png" srcSet="/img/bridge.png" media="(min-width: 1023px)" />

            <Img src="/img/bridge.png" alt="Kootenai Falls Swinging Bridge in Montana" loading="lazy" />
          </picture>
        </Flex>
        <Box width={[1]} maxWidth={['unset', '450px']}>
          <Heading as="h2" fontFamily="script" fontSize={['68px', '68px', '100px']} mb={[3, 4]}>
            Events
          </Heading>
          <Text as="p" fontFamily="sans" letterSpacing="0.25em" mb={[4]}>
            FALL RETREAT
          </Text>
          <Text as="p" fontSize={[1, 1, 2]} fontFamily="sans" lineHeight="1.5" mb={[4]}>
            Join us in Northwest Montana in September for our Fall Retreat. More details to come.{' '}
            <Link href="mailto:northernpinewellnessgroup.mtwa@gmail.com">Send us an email</Link> for more information,
            and sign up for our newsletter below for updates.
          </Text>
          {/* <Button variant="inverseWhite" href="mailto:northernpinewellnessgroup.mtwa@gmail.com">
            Contact Us
          </Button> */}
          <Signup />
        </Box>
      </Wrapper>
      {/* </Fade> */}
    </Flex>
  );
};

export default Event;
