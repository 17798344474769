import { useState } from 'react';
import { Box, Flex, Link, Button, Image } from 'rebass/styled-components';
import { MAX_WIDTH_DESKTOP, COLOR } from 'styles/constants';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2';
import styled from 'styled-components';
import { Icon } from './Icons/Icon';

const StyledLink = styled(AnchorLink)`
  font-family: 'PT sans';
  color: ${COLOR.brown};
  margin: 0 1rem;

  :nth-child(3) {
    border: 1px solid ${COLOR.brown};
    padding: 15px 20px;
    margin-right: 0;

    :hover {
      background: ${COLOR.brown};
      color: ${COLOR.white};
    }

    @media (max-width: 40em) {
      padding: 8px 16px;
    }
  }

  @media (max-width: 40em) {
    margin: 1rem 0;
  }
`;

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  return (
    <header>
      <Flex
        justifyContent="center"
        bg={COLOR.white}
        width={1}
        sx={{ position: 'fixed', zIndex: 4, boxShadow: '5px 5px 28px 0 rgb(0 0 0 / 3%)' }}
        height={['64px', 'auto']}
      >
        <Flex
          maxWidth={MAX_WIDTH_DESKTOP}
          py={[3, 4]}
          px={[3, 4]}
          justifyContent="space-between"
          alignItems="center"
          width={1}
        >
          <Image
            href="/"
            pr={3}
            src="img/logo.svg"
            alt="Northern Pine Wellness Group mt.wa logo"
            height={[30, 40]}
            width="auto"
          />
          <Box as="nav" display={['none', 'block']}>
            <StyledLink offset="50" href="#about">
              About
            </StyledLink>
            <StyledLink offset="50" href="#contact">
              Contact
            </StyledLink>
            <StyledLink offset="50" href="#events">
              Events
            </StyledLink>
          </Box>
          <Button
            display={['block', 'none']}
            onClick={() => setIsNavOpen(!isNavOpen)}
            pr={0}
            bg={COLOR.white}
            variant="inverse"
            sx={{ border: 'none' }}
            aria-label={isNavOpen ? 'close navigation menu' : 'open navigation menu'}
          >
            {isNavOpen ? <Icon type="close" /> : <Icon type="menu" />}
          </Button>
        </Flex>
      </Flex>
      {/* Mobile Nav */}
      <Box
        as="nav"
        display={[isNavOpen ? 'flex' : 'none', 'none']}
        height={'100%'}
        width={'100%'}
        bg={COLOR.white}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{ position: 'fixed', zIndex: 2 }}
      >
        <StyledLink onClick={() => setIsNavOpen(false)} href="#about">
          About
        </StyledLink>
        <StyledLink onClick={() => setIsNavOpen(false)} href="#contact">
          Contact
        </StyledLink>
        <StyledLink onClick={() => setIsNavOpen(false)} href="#events">
          Events
        </StyledLink>
      </Box>
    </header>
  );
};

export default Header;
