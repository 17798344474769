import { COLOR } from 'styles/constants';
import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';
import { useFormFields, useMailChimpForm } from 'use-mailchimp-form';

const url =
  'https://northernpinewellnessgroup-mtwa.us14.list-manage.com/subscribe/post?u=9228e915c7d9fdda4250a7d04&amp;id=3885374525';

const StyledForm = styled.form`
  label {
    display: flex;
    flex-direction: column;
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
  }

  input {
    border: 1px solid ${COLOR.brown};
    padding: 15px 20px;
    margin-top: 10px;
    margin-right: 1rem;
    font-size: 16px;
    letter-spacing: 1.25;
    font-family: 'PT Sans', sans-serif;

    @media (max-width: 40em) {
      padding: 8px 16px;
    }
  }

  button {
    padding: 15px 20px;
    margin-top: 10px;
    border: 1px solid transparent;
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    background: ${COLOR.brown};
    color: ${COLOR.white};
    @media (max-width: 40em) {
      padding: 8px 16px;
    }
    :hover {
      background: ${COLOR.white};
      border: 1px solid ${COLOR.brown};
      color: ${COLOR.brown};
    }
  }
  input[type='text'] {
    font-size: 16px;
  }

  p {
    font-family: 'PT Sans', sans-serif;
    margin: 1rem 0;
  }
`;

const MailchimpForm = () => {
  const { loading, error, success, message, handleSubmit } = useMailChimpForm(url);

  const { fields, handleFieldChange }: { fields: any; handleFieldChange: any } = useFormFields({
    EMAIL: '',
  });

  return (
    <>
      <StyledForm
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(fields);
        }}
      >
        <label>
          Email
          <Flex>
            <input
              id="EMAIL"
              type="email"
              value={fields.EMAIL}
              onChange={handleFieldChange}
              required
              aria-invalid={error}
            />
            <button>Sign Up</button>
          </Flex>
        </label>
        <p>
          {loading && 'submitting'}
          {error && message}
          {success && message}
        </p>
      </StyledForm>
    </>
  );
};

export default MailchimpForm;
