import { createGlobalStyle } from 'styled-components';
import { COLOR } from './constants';

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'Puzzled';
    src: url('/fonts/Puzzled-Regular.eot');
    src: url('/fonts/Puzzled-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Puzzled-Regular.woff2') format('woff2'),
        url('/fonts/Puzzled-Regular.woff') format('woff'),
        url('/fonts/Puzzled-Regular.ttf') format('truetype'),
        url('/fonts/Puzzled-Regular.svg#Puzzled-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html,
  body {
    line-height: 1.3;
  }

  body {
    overflow-x: hidden;
    max-width: 100vw;
    width: 100%;
    color: ${COLOR.brown};
    background: ${COLOR.tan};
    font-family: 'PT Sans', sans-serif;
  }


  h2 {
    font-family: 'Puzzled', script;
  }
  img {
    max-width:  100%;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  hr {
    border: none;
    height: 1px;
    margin-top: 2px;
  }

  button {
    padding: 0;
    font-family:'PT Sans', sans-serif;
    cursor: pointer;

  }

  sup {
    vertical-align: top;
    position: relative;
    top: -0.5em;
    font-size: smaller;
  }

   a {
     text-decoration: none;
     color: inherit;
        &:focus,
        &:hover,
        &:visited,
        &:active {
            text-decoration: none;
        } 
    }
    font-family: 'PT Sans', sans-serif;
  `;

export default GlobalStyle;
