import { Flex, Heading, Text, Box } from 'rebass/styled-components';
import { COLOR } from 'styles/constants';
import Wrapper from 'components/Wrapper';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';
import { StyledAnchorButton } from 'styles/shared';

const Img = styled.img`
  @media (max-width: 40em) {
    margin-bottom: 32px;
    height: 400px;
    width: auto;
  }

  max-height: 580px;
  width: auto;
  margin-bottom: 0;
  object-fit: contain;
`;
const Mission = () => {
  return (
    <Flex bg={COLOR.white} justifyContent="center" flexWrap="wrap" flexDirection="row" py={[5, 5, 6]} width={1}>
      {/* <Fade triggerOnce> */}
      <Wrapper flexDirection={['column-reverse', 'row']} justifyContent="center">
        <Flex flexDirection="column" width={['100%', '50%']} pr={[0, 4, 5, 5]} alignItems="flex-start">
          <Heading as="h2" fontFamily="serif" fontSize={['28px', '28px', '38px']} mb={4}>
            Combining nutrition, fresh air and new perspectives
          </Heading>
          <Text as="p" fontSize={[1, 1, 2]} mb={[4]} fontFamily="sans" lineHeight="1.4">
            Whether you join us in the mountains of Montana, seaside on the San Juan Islands of the Pacific Northwest,
            or somewhere in between, we know one thing for certain: Nature provides the perfect opportunity for you to
            disconnect from your day-to-day and reconnect to what is important.
            <br />
            <br />
            We're building community by providing opportunities to slow down, be more mindful, unplug, connect with
            others, share information, nourish our souls and spend some time intentionally creating an individual path
            to what wellness looks like for ourselves.
          </Text>
          <StyledAnchorButton offset="100" href="#events">
            Upcoming events
          </StyledAnchorButton>
        </Flex>
        <Flex as="picture" justifyContent="center" alignItems="center" width={[1, 0.5]}>
          <source type="image/webp" srcSet="/img/christa-small.webp" media="(max-width: 1023px)" />
          <source type="image/webp" srcSet="/img/christa.webp" media="(min-width: 1023px)" />
          <source type="image/png" media="(max-width: 1023px)" srcSet="/img/christa-small.png 358w" />
          <source type="image/png" media="(min-width: 1024px)" srcSet="/img/christa.png" />
          <Img
            src="/img/christa.png"
            alt="Christa Robertson hiking in a forest in Bellingham, Washington"
            loading="lazy"
          />
        </Flex>
      </Wrapper>
      {/* </Fade> */}
    </Flex>
  );
};

export default Mission;
