import { Flex } from 'rebass/styled-components';
import { MAX_WIDTH_DESKTOP } from 'styles/constants';

const Wrapper = (props: any) => {
  return (
    <Flex width={1} maxWidth={['100vw', MAX_WIDTH_DESKTOP]} px={[3, 4]} {...props}>
      {props.children}
    </Flex>
  );
};

export default Wrapper;
