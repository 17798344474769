import { Flex, Heading, Text, Box, Link, Image } from 'rebass/styled-components';
import { COLOR } from 'styles/constants';
import Wrapper from 'components/Wrapper';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

const Img = styled.img`
  @media (max-width: 40em) {
    height: 400px;
    width: auto;
  }
  max-height: 580px;
  margin-bottom: 0;
  object-fit: contain;
`;

const Contact = () => {
  return (
    <Flex width={1} bg={COLOR.tan} justifyContent="center" py={[5, 5, 6]}>
      {/* <Fade triggerOnce style={{ display: 'flex', justifyContent: 'center', width: '100%' }}> */}
      <Wrapper flexWrap="wrap" flexDirection={['column-reverse', 'row']} justifyContent="center">
        <Box width={['100%', 1 / 2, 1 / 2]} pr={[0, 4, 5, 5]}>
          <Heading as="h2" fontFamily="script" fontSize={['68px', '68px', '100px']} mb={[3, 4]}>
            Get in Touch
          </Heading>
          <Text as="p" fontFamily="sans" sx={{ textTransform: 'uppercase' }} letterSpacing="0.25em" mb={[4]}>
            Contact Us
          </Text>
          <Text as="p" fontSize={[1, 1, 2]} fontFamily="sans" lineHeight="1.5">
            We'd love to hear from you. Send us an email: <br />
            <Link
              href="mailto:northernpinewellnessgroup.mtwa@gmail.com"
              sx={{ borderBottom: `1px solid ${COLOR.brown}`, wordBreak: 'break-all' }}
            >
              northernpinewellnessgroup.mtwa@gmail.com
            </Link>
            <br />
            <br />
            Or, give us a call:
            <br />
            <span style={{ borderBottom: `1px solid ${COLOR.brown}` }}>(406) 461-3089 </span>
            <br />
            <br />
            Follow us on Instagram: <br />
            <Link
              href="https://www.instagram.com/northernpinewellnessgroup_mtwa"
              sx={{ borderBottom: `1px solid ${COLOR.brown}` }}
            >
              @northernpinewellnessgroup_mtwa{' '}
            </Link>
          </Text>
        </Box>
        <Flex as="picture" width={[1, 0.5]} justifyContent="center" mb={[4, 0]} alignItems="center">
          <source type="image/webp" srcSet="/img/hike1.webp" />
          <source type="image/png" srcSet="/img/hike1.png" />
          <Img
            src="/img/hike1.png"
            alt="Pam Robertson from behind, walking on a wooden forest footpath"
            loading="lazy"
          />
        </Flex>
      </Wrapper>
      {/* </Fade> */}
    </Flex>
  );
};

export default Contact;
