import { Flex, Box, Text, Heading } from 'rebass/styled-components';
import { COLOR } from 'styles/constants';

interface IconTypes {
  color?: string;
  type: 'facebook' | 'insta' | 'email' | 'menu' | 'close';
  padding?: string;
}

const Icon = ({ color = COLOR.tan, type, padding = '0 0 0 0' }: IconTypes) => {
  const MenuIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 9"
        preserveAspectRatio="xMinYMin meet"
        width="100%"
        height="100%"
      >
        <line x1="0.5" y1="8.5" x2="16.5" y2="8.5" stroke="#424242" strokeLinecap="round" />
        <line x1="0.5" y1="4.5" x2="16.5" y2="4.5" stroke="#424242" strokeLinecap="round" />
        <line x1="0.5" y1="0.5" x2="16.5" y2="0.5" stroke="#424242" strokeLinecap="round" />
      </svg>
    );
  };

  const CloseIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19 16"
        preserveAspectRatio="xMinYMin meet"
        width="100%"
        height="100%"
      >
        <path d="M1 15L18 0.999999" stroke="#424242" strokeLinecap="round" />
        <path d="M18 15L1 1" stroke="#424242" strokeLinecap="round" />
      </svg>
    );
  };

  const InstaIcon = () => {
    return (
      <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        preserveAspectRatio="xMinYMin meet"
        width="100%"
        height="100%"
      >
        <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z" />
      </svg>
    );
  };
  const FaceIcon = () => {
    return (
      <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="auto"
        height="auto"
        preserveAspectRatio="xMinYMin meet"
      >
        <path d="M12,2C6.477,2,2,6.477,2,12c0,5.013,3.693,9.153,8.505,9.876V14.65H8.031v-2.629h2.474v-1.749 c0-2.896,1.411-4.167,3.818-4.167c1.153,0,1.762,0.085,2.051,0.124v2.294h-1.642c-1.022,0-1.379,0.969-1.379,2.061v1.437h2.995 l-0.406,2.629h-2.588v7.247C18.235,21.236,22,17.062,22,12C22,6.477,17.523,2,12,2z" />
      </svg>
    );
  };
  const EmailIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        viewBox="0 0 256 256"
        preserveAspectRatio="xMinYMin meet"
      >
        <g transform="translate(128 128) scale(0.72 0.72)">
          <g transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)">
            <path
              fill={color}
              d="M 45 51.815 l 45 -33.87 v -1.967 c 0 -2.03 -1.646 -3.676 -3.676 -3.676 H 3.676 C 1.646 12.302 0 13.948 0 15.978 v 1.967 L 45 51.815 z"
              transform=" matrix(1 0 0 1 0 0) "
              strokeLinecap="round"
            />
            <path
              fill={color}
              d="M 47.405 60.019 c -0.712 0.536 -1.559 0.804 -2.405 0.804 s -1.693 -0.268 -2.405 -0.804 L 0 27.958 v 46.064 c 0 2.03 1.646 3.676 3.676 3.676 h 82.648 c 2.03 0 3.676 -1.646 3.676 -3.676 V 27.958 L 47.405 60.019 z"
              transform=" matrix(1 0 0 1 0 0) "
              strokeLinecap="round"
            />
          </g>
        </g>
      </svg>
    );
  };

  const Icons = {
    facebook: <FaceIcon />,
    insta: <InstaIcon />,
    email: <EmailIcon />,
    menu: <MenuIcon />,
    close: <CloseIcon />,
  };

  const IconComponent = () => {
    return Icons[type];
  };

  return (
    <Box width={['19px', '28px']} m={padding} display="flex" aria-hidden>
      {Icons[type]}
    </Box>
  );
};
export { Icon };
