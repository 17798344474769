import styled from 'styled-components';
import { Flex, Box, Heading, Text, Image } from 'rebass/styled-components';
import { COLOR } from 'styles/constants';
import Wrapper from 'components/Wrapper';
import { Fade } from 'react-awesome-reveal';
import { StyledAnchorButton } from 'styles/shared';

const Img = styled.img`
  width: auto;
  max-height: 700px;
  @media (max-width: 40em) {
    width: auto;
  }
  object-fit: contain;
`;

const Hero = () => {
  return (
    <Flex
      justifyContent={['flex-start', 'center']}
      alignItems={['flex-start', 'center']}
      width={1}
      pt={[5, 6]}
      pb={[5, 5, 6]}
      bg={COLOR.white}
    >
      {/* <Fade triggerOnce> */}
      <Wrapper
        flexDirection={['column', 'row']}
        alignItems="center"
        color={'primary'}
        pt={0}
        justifyContent="space-between"
        height={['auto', 'auto', 'auto', 'auto', '100vh']}
      >
        <Flex
          as="picture"
          width={'100%'}
          pr={[0, '40px']}
          justifyContent="center"
          height={['400px', 'auto', 'auto']}
          minHeight={['50vh', 'auto']}
        >
          <source type="image/webp" srcSet="/img/mountain1-small.webp" media="(max-width: 1023px)" />
          <source type="image/webp" srcSet="/img/mountain1.webp" media="(min-width: 1023px)" />
          <source type="image/png" media="(max-width: 1023px)" srcSet="/img/mountain1-small.png 358w" />
          <source type="image/png" media="(min-width: 1024px)" srcSet="/img/mountain1.png 884w" />
          <Img src="/img/mountain1.png" alt="Mount Baker" />
        </Flex>
        <Flex width={1} maxWidth={['100%', 350, 430, 430]} pt={[4, 0]} flexDirection="column" alignItems="flex-start">
          <Heading as="h1" color={COLOR.brown} fontSize={[4, 4, 5]} mb={[3, 4]} fontFamily="serif">
            Northern Pine
            <br /> Wellness Group{' '}
            <Text fontFamily="serif" fontSize={1} as="span">
              mt.wa
            </Text>
          </Heading>
          <Text as="p" fontSize={[1, 1, 2]} mb={[4]} fontFamily="sans" lineHeight={1.4}>
            We are on a collective mission to be healthier &mdash; through experiences and adventure; to eat well and
            share information; to connect, support and explore what individual wellness looks like. We invite you to
            join us on a path toward a healthier lifestyle.
          </Text>
          <StyledAnchorButton offset="100" href="#about">
            Learn More
          </StyledAnchorButton>
        </Flex>
      </Wrapper>
      {/* </Fade> */}
    </Flex>
  );
};

export default Hero;
