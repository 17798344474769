import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2';
import { COLOR } from 'styles/constants';

const StyledAnchorButton = styled(AnchorLink)`
  padding: 15px 20px;
  font-family: 'PT sans';
  color: ${COLOR.white};
  background: ${COLOR.brown};
  border: 1px solid transparent;

  :hover {
    background: ${COLOR.white};
    color: ${COLOR.brown};
    border: 1px solid ${COLOR.brown};
  }

  @media (max-width: 40em) {
    padding: 8px 16px;
  }
`;

export { StyledAnchorButton };
