import { Text, Heading, Flex, Box } from 'rebass/styled-components';
import { COLOR } from 'styles/constants';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

const Card = () => {
  return (
    <Box width={1}>
      <Flex flexDirection="column">
        <Flex
          as="picture"
          width={[0.6, 0.6, 0.6, 0.44]}
          minWidth={200}
          height="auto"
          mb={[4, 4, 4, '-95px']}
          sx={{ zIndex: 1 }}
          alignSelf={['center', 'center', 'center', 'flex-end']}
        >
          <source type="image/webp" srcSet="/img/pamr-mobile.webp" media="(max-width: 1023px)" />
          <source type="image/webp" srcSet="/img/pamRobertson.webp" media="(min-width: 1023px)" />
          <source type="image/png" media="(max-width: 1023px)" srcSet="/img/pamr-mobile.png 358w" />
          <source type="image/png" media="(min-width: 1024px)" srcSet="/img/pamRobertson.png 884w" />
          <img src="/img/pamRobertson.png" alt="Pam Robertson sitting on a rocky beach in Washington" loading="lazy" />
        </Flex>
        <Box
          p={[4, 4, '38px', '45px']}
          pb={5}
          bg={COLOR.white}
          sx={{ boxShadow: '10px 10px 28px 0 rgb(0 0 0 / 10%)' }}
          width={[1, 1, 1, 0.9]}
        >
          <Heading as="h3" mb={2} fontSize={[3, '28px']} fontFamily="serif">
            Pam Robertson
          </Heading>
          <Text as="p" fontSize={1} fontFamily="sans" maxWidth="500px" mb={4}>
            Bellingham, Washington
          </Text>
          <Text as="p" fontSize={1} fontFamily="sans" lineHeight={1.4}>
            Pam is thrilled to be a part of Northern Pine Wellness Group mt.wa. She practices the idea that physical and
            emotional wellness can be found within the power of nature, rest, self reflection, nutrition and from the
            support of others.
            <br /> <br />
            Most recently, Pam's journey has led her to the Pacific Northwest, on a path that has drawn her to
            supporting and empowering others. That experience led her to want to offer others a safe and welcoming place
            to process their own journeys.
            <br /> <br />
            Pam's career has focused on the care of children and families in schools and social work settings. She
            received her degrees from Evergreen State College in Washington (Go Geoducks) in Health and Human Services.
            Pam's passions include hiking, finding solitude in the outdoors and testing her bravery in new adventures.
            Pam has been known to jump out of perfectly good airplanes, trek through Iceland and is always up for a good
            laugh. Pam believes that adventures bring growth, and connections with others bring harmony.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Card;
