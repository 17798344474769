import React, { useEffect } from 'react';
import GlobalStyles from 'styles/globalStyles';
import Hero from 'components/Hero';
import Header from 'components/Header';
import Footer from 'components/Footer';
import About from 'components/About';
import Mission from 'components/Mission';
import Contact from 'components/Contact';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { Flex } from 'rebass/styled-components';
import theme from 'styles/theme';
import { Box } from 'rebass/styled-components';
import Event from 'components/Event';
import Blockquote from 'components/Blockquote';

function App() {
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth',
  //   });
  // }, []);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Wellness retreats in Montana and Washington. Providing opportunities to slow down, be mindful, connect with others and create an individual path to wellness."
        />
        <title>Northern Pine Wellness Group | Wellness Retreats </title>
        <link rel="canonical" href="http://www.northernpinewellnessgroup-mtwa" />
        <meta name="robots" content="index, follow" />\
        <meta name="twitter:title" content="Northern Pine Wellness Group" />
        <meta
          name="twitter:description"
          content="Providing opportunities to slow down, be mindful, connect with others and create an individual path to wellness."
        />
        <meta name="facebook:title" content="Northern Pine Wellness Group" />
        <meta name="google-site-verification" content="i2zZhY7GEsdzPvJp9f5TlV4JJZ9y6dMtRx00-pAQvsU" />
        <meta
          name="facebook:description"
          content="Opportunities to be mindful and create an individual path to wellness."
        />
        <meta
          property="og:url"
          content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html"
        />
        <meta property="og:title" content="Northern Pine Wellness mt.wa" />
        <meta
          property="og:description"
          content="Opportunities to be mindful and create an individual path to wellness"
        />
        <meta property="og:image" content="/img/og.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#b3b4ad" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=PT+Sans&family=PT+Serif&display=swap" rel="stylesheet" />
      </Helmet>
      <Header />
      <Flex as="main" justifyContent="center" alignItems="center" flexDirection={'column'} width="100%" pt={[4]}>
        <Hero />
        <Blockquote />
        <Box as="section" id="about" width={1} minHeight="100vh">
          <Mission />
          <About />
        </Box>
        <Box as="section" id="events" width={1} minHeight="100vh">
          <Event />
        </Box>
        <Box as="section" id="contact" width={1} minHeight="100vh">
          <Contact />
        </Box>
      </Flex>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
